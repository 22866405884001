const preloader = () => {
  const bodyElement = document.body;
  const isHomePage = bodyElement.classList.contains("home");
  const preloaderElement = document.getElementById("preloader");
  const preloaderVideo = document.getElementById("preloader__video");

  if (preloaderVideo && isHomePage) {
    preloaderVideo.muted = true;
    preloaderVideo.controls = false;
    preloaderVideo.play().catch((error) => {
      console.error("Error attempting to play the video:", error);
    });

    preloaderVideo.onended = () => {
      setTimeout(() => {
        preloaderElement.classList.add("d-none");
      }, 300);
    };

  }
};

export default preloader;
