import "slick-carousel";

/* Custom SCSS */
import "./index.scss";

import themeMedia from "./js/themeMedia";
import preloader from "./js/preloader";
import navigator from "./js/navigator";
import tabs from "./js/tabs";
import footer from "./js/footer";
import lottieAnimations from "./js/lottieAnimations";
import homeHero from "./js/homeHero";

themeMedia();
preloader();
navigator();
tabs();
footer();
lottieAnimations();
homeHero();
