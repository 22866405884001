const footer = () => {
  $(() => {
    if ($(".footer__logos__client__list")) {
      $(".footer__logos__client__list").slick({
        dots: false,
        arrows: false,
        slidesToShow: 4,
        slidesPerRow: 1,
        autoplay: true,
        infinite: true,
        // speed: 300,
        // cssEase: 'linear',
        // autoplaySpeed: 0,
        autoplaySpeed: 1000,
        rows: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });
    }

    if ($(".footer__logos__partner__list")) {
      $(".footer__logos__partner__list").slick({
        dots: false,
        arrows: false,
        slidesToShow: 2,
        slidesPerRow: 1,
        autoplay: false,
        infinite: false,
        rows: 0,
      });
    }

    const servizioSelect = document.getElementById("form__servizio");
    if (servizioSelect) {
      servizioSelect.addEventListener("change", () => {
        servizioSelect.classList.remove("not__selected");
      });
    }

    const privacyCheckbox = document.getElementById("form__privacy");
    const privacyCol = document.querySelector(".col__privacy");
    if (privacyCheckbox && privacyCol) {
      privacyCheckbox.addEventListener("click", () => {
        if (privacyCheckbox.checked) {
          privacyCol.classList.add("checked");
        } else {
          privacyCol.classList.remove("checked");
        }
      });
    }
  });

  const footerMenuTitles = document.querySelectorAll("#menu-footer-menu .menu-item-has-children > a");
  if (footerMenuTitles.length) {
    footerMenuTitles.forEach((title) => {
      title.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
  }
};

export default footer;
