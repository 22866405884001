const navigator = () => {
  $(() => {
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("collapsed");
      $("#navbarToggler").toggleClass("aperto");
      $("body").toggleClass("noscroll");
    });

    const header = document.querySelector(".header");
    const buttonUp = document.getElementById("button__up");
    window.addEventListener("scroll", function () {
      if (window.scrollY > 20) {
        header.classList.add("scrolled");
        if (buttonUp) {
          buttonUp.classList.add("scrolled");
        }
      } else {
        header.classList.remove("scrolled");
        if (buttonUp) {
          buttonUp.classList.remove("scrolled");
        }
      }
    });

    const dropdowns = document.querySelectorAll("#menu-main-menu .menu-item-has-children");
    if (dropdowns.length > 0) {
      dropdowns.forEach((dropdown) => {
        const toggler = dropdown.querySelector("a");
        const subMenu = dropdown.querySelector(".sub-menu");

        if (toggler && subMenu) {
          toggler.addEventListener("click", () => {
            dropdown.classList.toggle("open");
          });
        }
      });
    }
  });
};

export default navigator;
