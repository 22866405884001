const homeHero = () => {
  $(() => {
    const homeHero = document.querySelector(".home__hero");
    if (homeHero) {
      const video = homeHero.querySelector(".video_background");
      const iconaAudio = homeHero.querySelector(".iconaAudio");

      if (video) {
        if (video.muted) {
          iconaAudio.classList.add("muted");
        }

        if (iconaAudio) {
          iconaAudio.addEventListener("click", () => {
            if (video.muted) {
              video.muted = false;
              iconaAudio.classList.remove("muted");
            } else {
              video.muted = true;
              iconaAudio.classList.add("muted");
            }
          });
        }
      }
    }
  });
};

export default homeHero;
