const tabs = () => {

  $(() => {
    const tabBtnItems = document.querySelectorAll(".tabs__btn__item");
    if (tabBtnItems.length > 0) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (params) {
        const paramTab = params.get("tab");
        if (paramTab) {
          deactivateAllElements('.tabs__btn__item');
          deactivateAllElements('.tabs__content__item')
          document.getElementById('btn' + paramTab).parentElement.classList.add('active')
          document.getElementById('tab' + paramTab).classList.add('active')
        }
      }
  
      tabBtnItems.forEach((item) => {
        const btn = item.querySelector(".tabs__btn__btn");
        btn.addEventListener("click", () => {
          if (!item.classList.contains("active")) {
            deactivateAllElements('.tabs__btn__item');
            item.classList.add("active");
          }
          const tabs = document.querySelectorAll(".tabs__content__item");
          if (tabs.length > 0) {
            const tab = document.getElementById(btn.getAttribute("data-tab"));
            if (!tab.classList.contains("active")) {
              deactivateAllElements('.tabs__content__item')
              tab.classList.add("active");
              tab.classList.add("show");
            }
          }
        });
      });
    }
  })
  
};

export default tabs;

const deactivateAllElements = (selector) => {
  const list = document.querySelectorAll(selector);
  list.forEach(item => {
    item.classList.remove('active')
    item.classList.remove("show");

  })
}

